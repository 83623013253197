module.exports = function(gantt) {

	gantt.$keyboardNavigation.TaskRow = function (taskId) {
		if (!taskId) {
			var rootLevel = gantt.getChildren(gantt.config.root_id);
			if (rootLevel[0]) {
				taskId = rootLevel[0];
			}
		}
		this.taskId = taskId;
		if (gantt.isTaskExists(this.taskId)) {
			this.index = gantt.getTaskIndex(this.taskId);
		}
	};

	gantt.$keyboardNavigation.TaskRow.prototype = gantt._compose(
		gantt.$keyboardNavigation.KeyNavNode,
		{
			_handlers: null,
			isValid: function () {
				return gantt.isTaskExists(this.taskId) && (gantt.getTaskIndex(this.taskId) > -1);
			},
			fallback: function () {
				if (!gantt.getVisibleTaskCount()) {
					var header = new gantt.$keyboardNavigation.HeaderCell();
					if (!header.isValid()) return null;
					else return header;
				} else {

					var nextIndex = -1;
					if (gantt.getTaskByIndex(this.index - 1)) {
						nextIndex = this.index - 1;
					} else if (gantt.getTaskByIndex(this.index + 1)) {
						nextIndex = this.index + 1;
					} else {
						var index = this.index;
						while (index >= 0) {
							if (gantt.getTaskByIndex(index)) {
								nextIndex = index;
								break;
							}
							index--;
						}

					}
					if (nextIndex > -1) {
						return new gantt.$keyboardNavigation.TaskRow(gantt.getTaskByIndex(nextIndex).id);
					}
				}
			},

			fromDomElement: function(el){
				if(gantt.config.keyboard_navigation_cells){
					return null;
				}

				var taskId = gantt.locate(el);
				if(gantt.isTaskExists(taskId)){
					return new gantt.$keyboardNavigation.TaskRow(taskId);
				}else{
					return null;
				}
			},

			getNode: function () {
				if (gantt.isTaskExists(this.taskId) && gantt.isTaskVisible(this.taskId)) {
					if (gantt.config.show_grid) {
						return gantt.$grid.querySelector(".gantt_row[" + gantt.config.task_attribute + "='" + this.taskId + "']");
					} else {
						return gantt.getTaskNode(this.taskId);
					}
				}
			},

			focus: function (keptFocus) {
				if(!keptFocus) {
					var pos = gantt.getTaskPosition(gantt.getTask(this.taskId));
					var height = gantt.config.row_height;
					var scroll = gantt.getScrollState();

					var viewWidth;
					if(gantt.$task){
						viewWidth = gantt.$task.offsetWidth;
					}else{
						viewWidth = scroll.inner_width;
					}

					var viewHeight;
					if(gantt.$grid_data || gantt.$task_data){
						viewHeight = (gantt.$grid_data || gantt.$task_data).offsetHeight;
					}else{
						viewHeight = scroll.inner_height;
					}

					if (pos.top < scroll.y || pos.top + height > (scroll.y + viewHeight)) {

						gantt.scrollTo(null, pos.top - height * 5);

					} else if (gantt.config.show_chart && (pos.left < scroll.x || pos.left > (scroll.x + viewWidth))) {
						gantt.scrollTo(pos.left - gantt.config.task_scroll_offset);

					}
				}

				gantt.$keyboardNavigation.KeyNavNode.prototype.focus.apply(this, [keptFocus]);
			},

			keys: {
				"pagedown": function () {
					if (gantt.getVisibleTaskCount()) {
						this.moveTo(new gantt.$keyboardNavigation.TaskRow(gantt.getTaskByIndex(gantt.getVisibleTaskCount() - 1).id));
					}
				},
				"pageup": function () {
					if (gantt.getVisibleTaskCount()) {
						this.moveTo(new gantt.$keyboardNavigation.TaskRow(gantt.getTaskByIndex(0).id));
					}
				},
				"up": function () {
					var nextElement = null;
					var prevTask = gantt.getPrev(this.taskId);
					if (!prevTask) {
						nextElement = new gantt.$keyboardNavigation.HeaderCell();
					} else {
						nextElement = new gantt.$keyboardNavigation.TaskRow(prevTask);
					}
					this.moveTo(nextElement);
				},
				"down": function () {
					var nextTask = gantt.getNext(this.taskId);
					if (nextTask) {
						this.moveTo(new gantt.$keyboardNavigation.TaskRow(nextTask));
					}
				},

				"shift+down": function(){
					if(gantt.hasChild(this.taskId) && !gantt.getTask(this.taskId).$open){
						gantt.open(this.taskId);
					}
				},
				"shift+up": function(){
					if(gantt.hasChild(this.taskId) && gantt.getTask(this.taskId).$open){
						gantt.close(this.taskId);
					}
				},
				"shift+right": function(){
					var prevId = gantt.getPrevSibling(this.taskId);
					if(gantt.isTaskExists(prevId) && !gantt.isChildOf(this.taskId, prevId)){
						var parent = gantt.getTask(prevId);
						parent.$open = true;
						var result = gantt.moveTask(this.taskId, -1, prevId);
						if(result !== false)
							gantt.updateTask(this.taskId);
					}
				},
				"shift+left": function(){
					var parent = gantt.getParent(this.taskId);
					if(gantt.isTaskExists(parent)){
						var result =  gantt.moveTask(this.taskId, gantt.getTaskIndex(parent) + 1, gantt.getParent(parent));
						if(result !== false)
							gantt.updateTask(this.taskId);
					}
				},

				// select
				"space": function (e) {
					if (!gantt.isSelectedTask(this.taskId)) {
						gantt.selectTask(this.taskId);
					} else {
						gantt.unselectTask(this.taskId);
					}
				},

				// collapse
				"ctrl+left": function (e) {
					gantt.close(this.taskId);
				},
				// expand
				"ctrl+right": function (e) {
					gantt.open(this.taskId);
				},

				// delete task
				"delete": function (e) {
					gantt.$click.buttons["delete"](this.taskId);
				},

				// open lightbox
				"enter": function () {
					gantt.showLightbox(this.taskId);
				},

				// add subtask
				"ctrl+enter": function () {
					gantt.createTask({}, this.taskId);
				}
			}
		}
	);
	gantt.$keyboardNavigation.TaskRow.prototype.bindAll(gantt.$keyboardNavigation.TaskRow.prototype.keys);

};